<template>
  <div class="card p-0 m-0" v-if="order">
    <div class="card-body p-0 m-0">
      <div class="d-flex justify-content-between ">
        <h4 class="card-title ms-2 mt-2">Orderdetailpagina</h4>
        <div class="m-2">
          <!-- force update for commit-->
          <!-- state 2,9 for orders with errors -->
          <button v-if="order?.status?.id == 2 || order?.status?.id == 9" 
            type="button" @click="resentOrder()" class="btn btn-primary me-2">
            <i class="bi bi-arrow-repeat me-1"></i>
            <span class="d-none d-md-inline">Opnieuw</span>
          </button>
          <!-- temp only -->
          <button v-if="isAdmin" type="button" @click="deleteOrder()" class="btn btn-danger mt-2 mt-lg-0">
            <i class="bi bi-trash me-1"></i>
            <span class="d-none d-md-inline">Verwijder</span>
          </button>
        </div>
      </div>
      <div class="row p-0 ms-0 me-2">
        <div class="col-12 p-1 m-0 d-flex justify-content-between align-items-center">
          <h3>{{ order?.customer?.organisation_name ? order?.customer?.organisation_name : order?.customer?.name + '*'  }}{{ getHeader(order?.reference) }}{{ getHeader(order?.vo_nummer) }}{{ getHeader(getFormatedDateTime(order?.updated_at)) }}</h3>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 mb-2">
          <div class="d-flex align-items-center">
            <i class="bi bi-circle-fill me-2" :class="getColor(order?.status?.id)"></i>
            <select v-if="order?.status" v-model="order.status.id" @change="updateOrderStatus" class="form-select" :disabled="isStatusLocked">
              <option v-for="status in orderstatussen" :key="status.id" :value="status.id">
                {{ status.label }}
              </option>
            </select>
          </div>
          <div v-if="!order?.history || order?.history?.length == 0" v-html="order?.error_message"></div>
          <div v-for="history in sortedHistory" :key="history.id" class="mt-2 small-text">
            <b>{{ getFormattedDate(history.created_at) }}</b> - <span v-html="history.error_message"></span>
            <ul v-if="history.line_errors && history.line_errors.length" class="mt-1 ms-3 mb-0 line-errors">
              <li v-for="(error, index) in history.line_errors" :key="index">
                <span v-html="error.message"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 mb-3 py-2">
          <OrderDetailTab @click="select"></OrderDetailTab>
          <div class="border no-top-border p-3 custom-border-radius"> 
            <div class="row" v-if="selectedView == 'object'">
              <div class="orderline-header">
                <div class="card mb-2">
                  <div class="card-body">
                    <h4 class="card-title">Order</h4>
                      <h6 class="card-subtitle mb-2 text-body-secondary">
                        <b>Debiteur: </b> {{ order.output?.debtorCode }} - 
                        <b>Referentie: </b> {{ order?.output?.reference }} - 
                        <b>Totaalprijs: </b> {{ formatCurrency(order?.output?.netAmountCustomer, true) }}
                      </h6>
                      <div class="col-auto">
                        <label for="invoiceAddressCode" class="col-form-label">Factuuradrescode</label>
                        <EditOrderProp
                          id="invoiceAddressCode"
                          :type="'text'"
                          :classStr="'text-begin'"
                          :styleStr="'max-width: 8em;'"
                          :index="1"
                          :orderProperty="{value: invoiceAddressCode}"
                          @changed="invoiceAddressCodeChanged"
                        />
                      </div>
                  </div>
                </div>
              </div>
              <div class="orderlines-list">
                <div v-for="(orderline, index) in orderlines" :key="index" class="orderline-item">
                  <div class="card mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Orderregel {{ orderline.orderLineNumber }}</h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">
                        <b>Aantal:</b> {{ orderline.qtyOrdered.toFixed(0) }} - 
                        <b>Prijs ex.btw:</b> {{ formatCurrency(orderline.linePrice, true) }}
                      </h6>
                      <div class="row custom-row ms-0 me-0">
                        <div class="col-auto">
                          <label for="productCode" class="col-form-label">Product code</label>
                          <EditOrderlineProp
                            id="productCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 12em;'"
                            :rowIndex="index"
                            :orderlineProperty="{ value: orderline.productIdentifier?.productCode }"
                            @changed="productCodeChanged"
                          />
                        </div>
                        <div class="col-auto">
                          <label for="unitCode" class="col-form-label">Unit code</label>
                          <EditOrderlineProp
                            id="unitCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 5em;'"
                            :rowIndex="index"
                            :orderlineProperty="{ value: orderline.unitCode }"
                            @changed="unitCodeChanged"
                          />
                        </div>
                        <div class="col-auto">
                          <label for="deliveryAddressCode" class="col-form-label">Delivery adres</label>
                          <EditOrderlineProp
                            id="deliveryAddressCode"
                            :type="'text'"
                            :classStr="'text-begin'"
                            :styleStr="'max-width: 8em;'"
                            :rowIndex="index"
                            :orderlineProperty="{value: orderline.deliveryAddress?.id}"
                            @changed="addressCodeChanged"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <json-tree v-if="selectedView == 'object'" :data="order.output"></json-tree>
            <editable-json-view
              v-if="selectedView == 'json'"
              ref="editableJsonView"
              :editable="order.status?.id != 1 && order.status?.id != 3 && order.status?.id != 6"
              :initialJson="order.output"
              @updateJson="updateJson"
              @showToast="showToast"
            ></editable-json-view> 
            <div class="position-relative">
              <pre v-if="selectedView == 'input'" class="input-pre">
                <button type="button" class="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 m-2 py-1 px-2" 
                        @click="copyToClipboard(cleanFormattedInput)"><i class="bi bi-clipboard"></i></button>
                <code :class="inputType">{{ cleanFormattedInput }}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
      <!-- Add any modals or additional components if needed -->
      <div class="modal fade" id="confirmResentModal" tabindex="-1" aria-labelledby="confirmResentModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmResentModalLabel">Bevestiging</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Let op, dit maakt aangebrachte wijzigingen in de order ongedaan.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuleren</button>
              <button type="button" class="btn btn-primary" @click="confirmResent">Bevestigen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import ServiceFactory from "../services/ServiceFactory";
import Utils from "../utils/Utils";
import EditOrderProp from "../components/EditOrderProp.vue";
import EditOrderlineProp from "../components/EditOrderlineProp.vue";
import JsonTree from "../components/JsonTree.vue";
import OrderDetailTab from "../components/OrderDetailTab.vue";
import EditableJsonView from "../components/EditableJsonView.vue";
import { XMLParser } from 'fast-xml-parser';
import { getItem } from '../utils/localDB';
import OAuth2AzureService from "@/services/OAuth2AzureService";

export default {
  name: "OrderDetailPage",
  props: ["id"],
  emits: ["showToast"],
  components: {
    EditOrderlineProp,
    EditOrderProp,
    JsonTree,
    OrderDetailTab,
    EditableJsonView,
  },
  data() {
    return {
      orderService: {},
      order: null,
      orderlines: [],
      selectedView: "object",
      orderstatussen: [],
      originalStatusId: null,
      invoiceAddressCode: null,
      formattedInput: '',
      inputType: '',
      isAdmin: false,
      service: null,
    };
  },
  computed: {
    isStatusLocked() {
      return false //this.order.status.id === 1 || this.order.status.id === 3 || this.order.status.id === 6;
    },
    sortedHistory() {
      if (!this.order?.history) {
        return [];
      }
      return [...this.order.history].sort((a, b) => {
        if (!a.created_at) return 1;
        if (!b.created_at) return -1;
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
    cleanFormattedInput() {
      const lines = this.formattedInput.split('\n');
      
      // Verwijder lege regels aan het begin
      while (lines.length > 0 && lines[0].trim() === '') {
        lines.shift();
      }
      
      // Verwijder lege regels aan het einde
      while (lines.length > 0 && lines[lines.length - 1].trim() === '') {
        lines.pop();
      }
      
      return lines.join('\n');
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForOrderStatus(status);
    },
    getPrice(price) {
      return Utils.formatCurrency(price);
    },
    async loadOrderDetail() {
      try {
        this.orderService = ServiceFactory.getService("OrderService");
        this.order = await this.orderService.getOrder(this.id);
        
        if (!this.order || !this.order.id) {
          this.$router.push('/orders');
          return;
        }

        this.orderlines = this.order?.output?.lines || [];
        this.orderstatussen = await this.orderService.getOrderstatussen();
        this.originalStatusId = this.order?.status?.id; 
        this.invoiceAddressCode = this.order?.output?.invoiceAddressCode;
        this.formatInput();
      } catch (error) {
        console.error('Error loading order details:', error);
        this.$router.push('/orders');
      }
    },
    getHeader(txt) {
      return txt && txt !== "" ? " - " + txt : "";
    },
    getFormattedDate(date) {
      const formattedDate = Utils.formatDateTimeString(date);
      if (!formattedDate) {
        return "#:#";
      }
      return formattedDate;
    },
    getFormatedDateTime(date) {
      const formattedDate = Utils.getCustomFormattedDate(date);
      if (!formattedDate) {
        return "#:#";
      }
      return formattedDate;
    },
    addressCodeChanged(value, index) {
      console.log(`addressCodeChanged: (${value}, ${index})`);
      this.orderlines[index].deliveryAddress.id = value;
      this.order.output.lines[index].deliveryAddress.id = value;
      // Update the order on the server if needed
    },
    unitCodeChanged(newUnitCode, index) {
      this.orderlines[index].unitCode = newUnitCode;
      this.order.output.lines[index].unitCode = newUnitCode;
    },
    productCodeChanged(value, index) {
      console.log(`productCodeChanged: (${value}, ${index})`);
      this.orderlines[index].productIdentifier.productCode = value;
      this.order.output.lines[index].productIdentifier.productCode = value;
      // Update the order on the server if needed
    },
    select(view) {
      this.selectedView = view;
    },
    async updateJson(newJson) {
      this.jsonChanged = true;
      this.order.output = newJson;
      this.orderlines = this.order.output?.lines || [];
    },
    showToast(message) {
      this.$emit("showToast", message);
    },
    async updateOrderStatus() {
      if (this.isStatusLocked) {
        this.$emit("showToast", ["Deze orderstatus kan niet worden gewijzigd", "bg-warning"]);
        this.order.status.id = this.originalStatusId;
        return;
      }
      try {
        const result = await this.orderService.updateOrderStatus(this.order.id, this.order.status.id);
        if (result.success) {
          this.$emit("showToast", ["Orderstatus succesvol bijgewerkt", "bg-success"]);
          this.originalStatusId = this.order.status.id;
        } else {
          this.$emit("showToast", [`Fout bij het bijwerken van de orderstatus: ${result.message}`, "bg-danger"]);
          this.order.status.id = this.originalStatusId;
        }
      } catch (error) {
        console.error("Fout bij het updaten van de orderstatus:", error);
        this.$emit("showToast", ["Er is een fout opgetreden bij het bijwerken van de orderstatus", "bg-danger"]);
        this.order.status.id = this.originalStatusId;
      }
    },
    async resentOrder() {
      const editableJsonView = this.$refs.editableJsonView;
      if (editableJsonView) {
        const jsonContent = editableJsonView.jsonContent;
        this.order.output = JSON.parse(jsonContent);
      }
      const result = await this.orderService.retryOrder(this.order.id, this.order.output);
      if(result.success) {
        this.$router.push('/orders');
      } else {
        this.$emit('showToast', [`Er is een fout opgetreden bij het opnieuw versturen van de order: ${result.message.error}`, 'bg-danger']);
      }
    },
    async confirmResent() {
      this.modal.hide();
      await this.performResent();
    },
    async performResent() {
      const result = await this.orderService.retryOrder(this.order.id, this.order.output);
      if (result.success) {
        this.modal.hide(); 
      } else {
        this.$emit('showToast', [`Er is een fout opgetreden bij het opnieuw versturen van de order: ${result.message.error}`, 'bg-danger']);
      }
      this.loadOrderDetail();
    },
    invoiceAddressCodeChanged(invoiceAddressCode, index) {
      console.log(`Invoice Address Code gewijzigd: ${invoiceAddressCode} op index ${index}`);
      this.order.output.invoiceAddressCode = invoiceAddressCode;
    },
    formatInput() {
      if (!this.order?.input) {
        this.formattedInput = 'Geen input beschikbaar';
        this.inputType = 'text';
        return;
      }

      const input = this.order?.input;
      if (typeof input === 'string' && input.trim().startsWith('<')) {
        // Het lijkt XML te zijn
        this.formatXml(input);
      } else {
        // We gaan ervan uit dat het JSON is
        this.formatJson(input);
      }
    },
    formatXml(xml) {
      try {
        const parser = new XMLParser({ ignoreAttributes: false });
        // We parsen de XML om te controleren of het geldige XML is
        parser.parse(xml);
        // Als het parsen succesvol is, gebruiken we onze prettyPrintXml functie
        this.formattedInput = this.prettyPrintXml(xml);
        this.inputType = 'xml';
      } catch (err) {
        console.error('Fout bij het parsen van XML:', err);
        this.formattedInput = xml;
        this.inputType = 'xml';
      }
    },
    formatJson(json) { 
      try {
        const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
        this.formattedInput = JSON.stringify(parsedJson, null, 2);
        this.inputType = 'json';
      } catch (error) {
        console.error('Fout bij het formatteren van JSON:', error);
        this.formattedInput = typeof json === 'string' ? json : JSON.stringify(json, null, 2);
        this.inputType = 'json';
      } 
    },
    formatCurrency(amount, showCurrency = false) {
      return Utils.formatCurrency(amount, 'EUR', showCurrency);
    },
    prettyPrintXml(xml) {
      let formatted = '';
      let indent = '';
      const tab = '  '; // 2 spaties voor indentatie
      xml.split(/>\s*</).forEach(function(node) {
        if (node.match( /^\/\w/ )) {
          indent = indent.substring(tab.length); // Verminder indentatie
        }
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^/]$/ )) { // Verwijderd de backslash voor de forward slash
          indent += tab; // Verhoog indentatie
        }
      });
      return formatted.substring(1, formatted.length-3).trim();
    },
    async deleteOrder() {
      const confirm = window.confirm('Weet je zeker dat je deze order wilt verwijderen?');
      //check if to be sure
      if (confirm) {
        const result = await this.orderService.deleteOrder(this.order.id);
        console.log('result:', result);
        if (result.success) {
          this.$router.push('/orders');
        } else {
          this.$emit('showToast', ['Er is een fout opgetreden bij het verwijderen van de order', 'bg-danger']);
        }
      }
    },
    async checkAdminStatus() {
      const token = getItem('token');
      if (token) {
        try {
          const isAdmin = await this.service.checkAdminStatus(token);
          this.isAdmin = isAdmin;
        } catch (error) {
          console.error('Error checking admin status:', error);
          this.isAdmin = false;
        }
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => this.$emit("showToast", ["Gekopieerd naar klembord", "bg-success"]))
        .catch(err => this.$emit("showToast", [`Fout bij kopiëren: ${err.message}`, "bg-danger"]));
    },
  },
  mounted() {
    this.loadOrderDetail();
    this.service = new OAuth2AzureService();
    this.checkAdminStatus();
  },
};
</script>

<style scoped>
.custom-border-radius {
  border-radius: 0 0 7px 7px;
}
.no-top-border {
  border-top: none !important;
}
.orderlines-list {
  display: flex;
  flex-direction: column;
}
.orderlines-header {
  display: flex;
  flex-direction: column; 
}
.orderline-item {
  padding: 0.5em 0;
}
.custom-row {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1em;
  margin-bottom: 1em;
}
.orderline-item .row {
  margin-bottom: 0.5em;
}
@media (max-width: 767px) {
  .orderline-item .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* Style for disabled select options if needed */
select option:disabled {
  color: #999;
  font-style: italic;
}
.small-text {
  font-size: 0.875rem; /* Blijft hetzelfde als eerder */
  line-height: 1.2;
}

.small-text ul {
  padding-left: 1rem;
}

.small-text .line-errors {
  font-size: 0.625rem; /* Nog kleiner dan de hoofdtekst */
}

.small-text .line-errors li {
  margin-bottom: 0.2rem;
}

textarea {
  font-family: monospace;
  font-size: 14px;
}
.input-pre {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 500px;
  overflow-y: auto;
}
.input-pre code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  display: block;
  padding-left: 0;
  margin-left: -15px;
}
.input-pre code.xml {
  color: #0000ff;
}
.input-pre code.json {
  color: #006400;
}
</style>




