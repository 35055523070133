<template>
  <div class="editable-json-container">
    <button class="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 m-2 py-1 px-2" 
            @click="copyToClipboard"><i class="bi bi-clipboard"></i></button>
    <textarea v-model="jsonContent" :disabled="!editable" class="form-control" rows="25"></textarea>
  </div>
</template>

<script>
export default {
  name: 'EditableJsonView',
  props: ['initialJson', 'editable'],
  emits: ['updateJson', 'showToast'],
  data() {
    return {
      jsonContent: JSON.stringify(this.initialJson, null, 2),
    }
  },
  methods: {
    emitUpdate() {
      try {
        const parsedJson = JSON.parse(this.jsonContent);
        this.$emit('updateJson', parsedJson);
      } catch (error) {
        console.error('Ongeldige JSON:', error);
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.jsonContent)
        .then(() => this.$emit("showToast", ["Gekopieerd naar klembord", "bg-success"]))
        .catch(err => this.$emit("showToast", [`Fout bij kopiëren: ${err.message}`, "bg-danger"]));
    },
  },
  watch: {
    jsonContent() {
      this.emitUpdate();
    }
  }
}
</script>

<style scoped>
.editable-json-container {
  position: relative;
}
</style>